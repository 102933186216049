<template>

  <div class="misc-wrapper bg-white p-0 m-0">

    <b-overlay
      no-wrap
      :show="validate === -1"
      rounded="sm"
    />

    <div
      v-if="validate !== - 1"
      class="misc-inner p-2 p-sm-3"
    >
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ validate === 1 ? 'Thanh toán thành công! 🎉' : 'Thanh toán thất bại 🥹' }}
        </h2>
        <p class="mb-2">
          {{ validate === 1 ? `Bạn đã thanh toán thành công. Bạn sẽ được chuyển hướng về trang chủ sau ${countDown} s.` : `Thanh toán không thành công. Vui lòng kiểm tra và thực hiện lại. Bạn sẽ được chuyển hướng về trang chủ sau ${countDown} s` }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          to="/"
        >Về trang chủ</b-button>
        <b-img
          fluid
          :src="require('@/assets/images/pages/payment-success.webp')"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton, BOverlay } from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import usePaymentResult from './usePaymentResult';

export default {
  components: {
    BImg,
    BButton,
    BOverlay,
  },

  setup() {
    const {
      validateResult, countDown, startCountdown, validate,
    } = usePaymentResult();

    return {
      validateResult, countDown, startCountdown, validate,
    };
  },
  created() {
    this.validateResult();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
